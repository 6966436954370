html,
body {
  width: 100%;
  height: 100%;
  margin: 0px auto;
  padding: 0px;
  overflow-x: hidden;
}

@font-face {
  font-family: 'Aleo';
  src: local('Aleo'), url('./assets/fonts/Aleo/Aleo-Regular.ttf');
  font-weight: regular;
}

@font-face {
  font-family: 'Alice';
  src: local('Alice'), url('./assets/fonts/Alice/Alice-Regular.ttf');
  font-weight: regular;
}

@font-face {
  font-family: 'Amaranth';
  src: local('Amaranth'), url('./assets/fonts/Amaranth/Amaranth-Regular.ttf');
  font-weight: regular;
}

@font-face {
  font-family: 'Arima_Madurai';
  src: local('Arima_Madurai'),
    url('./assets/fonts/Arima_Madurai/ArimaMadurai-Regular.ttf');
  font-weight: regular;
}
